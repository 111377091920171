@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
}

* ::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* html {
  scroll-behavior: smooth;
}  */
a {
  text-decoration: none;
}

.description img{
  height: 300px;
  width: 100%;
}

@media (max-width: 1200px) {
  .description img {
    width: 90% !important;
    height: 350px !important;
  }
}
@media (max-width: 900px) {
  .description img {
    width: 100% !important;
    height: 310px !important;
  }
}
@media (max-width: 500px) {
  .description img {
    width: 100% !important;
    height: 200px !important;
  }
  .description h3{
    line-height: 20px;
   }
   .description h2{
     line-height: 20px;
    }

}